<template>
  <div id="dwv">
    <v-progress-linear
      color="primary"
      class="mt-0"
      :value="loadProgress"
      height="6"
    ></v-progress-linear>

    <div class="layerContainer">
      <canvas class="imageLayer">Only for HTML5 compatible browsers...</canvas>
      <div class="drawDiv"></div>
    </div>
  </div>
</template>

<script>
// import
import Vue from "vue";
import dwv from "dwv";

// gui overrides

// get element
dwv.gui.getElement = dwv.gui.base.getElement;

// Image decoders (for web workers)
dwv.image.decoderScripts = {
  jpeg2000: "/assets/dwv/decoders/pdfjs/decode-jpeg2000.js",
  "jpeg-lossless": "/assets/dwv/decoders/rii-mango/decode-jpegloss.js",
  "jpeg-baseline": "/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js",
  rle: "/assets/dwv/decoders/dwv/decode-rle.js"
};

export default {
  name: "dwv",
  props: ["uri"],
  components: {},
  data: function() {
    return {
      versions: {
        dwv: dwv.getVersion(),
        vue: Vue.version
      },
      dwvApp: null,

      loadProgress: 0,
      dataLoaded: false,
      metaData: null,
      showDicomTags: false
    };
  },
  mounted() {
    // create app
    this.dwvApp = new dwv.App();
    // initialise app
    this.dwvApp.init({
      containerDivId: "dwv",
      tools: this.tools
    });
    // handle load events
    let nReceivedError = null;
    let nReceivedAbort = null;
    this.dwvApp.addEventListener("load-start", (/*event*/) => {
      this.dataLoaded = false;
      nReceivedError = 0;
      nReceivedAbort = 0;
    });
    this.dwvApp.addEventListener("load-progress", event => {
      console.log("LOAD PROGRESS", event.loaded);
      this.loadProgress = event.loaded;
    });
    this.dwvApp.addEventListener("load", (/*event*/) => {
      // set dicom tags
      this.metaData = dwv.utils.objectToArray(this.dwvApp.getMetaData());
      console.log("i'm here in load and meta is", this.metaData);

      this.dataLoaded = true;
    });
    this.dwvApp.addEventListener("load-end", (/*event*/) => {
      this.loadProgress = 100;
      console.log(
        "i'm here in load ended and nerrros are ",
        nReceivedError,
        "and abort is",
        nReceivedAbort
      );
      if (nReceivedError) {
        this.loadProgress = 0;
        alert("Received errors during load. Check log for details.");
      }
      if (nReceivedAbort) {
        this.loadProgress = 0;
        alert("Load was aborted.");
      }
    });
    this.dwvApp.addEventListener("error", (/*event*/) => {
      console.log(event);
      ++nReceivedError;
    });
    this.dwvApp.addEventListener("abort", (/*event*/) => {
      console.log("why did i end up here?");
      ++nReceivedAbort;
    });

    // handle key events
    this.dwvApp.addEventListener("keydown", event => {
      this.dwvApp.defaultOnKeydown(event);
    });
    // handle window resize
    window.addEventListener("resize", this.dwvApp.onResize);

    // setup drop box
    //this.setupDropbox()

    // possible load from location
    dwv.utils.loadFromUri(this.uri, this.dwvApp);
  },
  methods: {
    onReset: function() {
      this.dwvApp.resetDisplay();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dwv {
  font-family: Arial, Helvetica, sans-serif;
  height: 90%;
}
</style>
