import { render, staticRenderFns } from "./fileView.vue?vue&type=template&id=5c1df9ca&"
import script from "./fileView.vue?vue&type=script&lang=js&"
export * from "./fileView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VScrollXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VProgressLinear,VScrollXReverseTransition,VSystemBar,VToolbar,VToolbarTitle})
